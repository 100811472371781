@import '../../../variables';
@import '../../../../node_modules/@chr/autoStyle/dist/sass/base/variables/chrBase_color_CHR';

.autocomplete {
  position: relative;

  .suggestions {
    position: absolute;
    z-index: 50;
    background-color: $white;
    border: 1px solid $focus-state-color;
    width: 100%;

    ul {
      margin: 0;
      padding: 0;

      &.recent-searches + .autocomplete-results {
        border-top: 1px solid $carrier-light-2-grey;
        margin-top: 8px;
        padding-top: 4px;
      }

      label {
        color: $carrier-disabled-grey;
        font-size: 10px;
        padding-left: 2px;
      }
    }

    li {
      list-style: none;

      a {
        border: 1px solid transparent;
        color: $black;
        cursor: pointer;
        display: block;
        font-weight: 300;
        padding: 2px 8px;

        &.recent-search-content {
          display: flex;

          .history-icon {
            background-image: url('/assets/images/history_default.svg');
            background-repeat: no-repeat;
            height: 14px;
            margin-top: 4px;
            min-width: 24px;
          }
        }
      }

      a:hover,
      a.selected {
        background: $chr-navisphere-light-blue;
        border-color: $chr-active-blue;
        color: $white;
        text-decoration: none;

        .history-icon {
          background-image: url('/assets/images/history_hover.svg');
        }
      }
    }
  }

  .ns-icon.ns-loading {
    position: absolute;
    top: 5px;
    right: 0;
  }
}
